<template>
    <div>
        <i @click="supprimerEcran" class="fa styleFavicons">&#xf1f8;</i>
    </div>
</template>

<script>
export default {
  name: 'DeleteEcran',
    props:[],
    emits: ['supprimerecran'],
    methods:{
        supprimerEcran(){
            this.$emit("supprimerecran")
        }
    }
}
</script>