<template>
    <div class="precedent">
        <i @click="idEcranMoins" class="fa styleFavicons">&#xf060;</i>
    </div>
</template>

<script>
export default {
  name: 'Precedent',
    props:[],
    emits: ["ecranprecedent"],
    methods:{
        idEcranMoins(){
            this.$emit('ecranprecedent')
        }

    }
}
</script>