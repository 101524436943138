<template>
    <h4>{{ page_addCookie().title }}</h4>
    <div class="sous_tire_ecran obligatoire">
        <div>Nom du cookie</div>
        <div>*</div>
    </div>
    <input type="text" v-model='nom'>
    <div class="sous_tire_ecran obligatoire">
        <div>{{ page_addCookie().p1 }}</div>
        <div>*</div>
    </div>
    <input type="text" v-model="short">
    <div class="sous_tire_ecran">{{ page_addCookie().p2 }}</div>
    <input size="30" type="text" v-model.lazy='long'>
    <div class="sous_tire_ecran obligatoire">
        <div>{{ page_addCookie().p3 }}</div>
        <div>*</div>
    </div>
    <input size="30" type="text" v-model='domain'>
    <div class="sous_tire_ecran obligatoire">
        <div>{{ page_addCookie().p4 }}</div>
        <div>*</div>
    </div>
    <input size="30" type="text" v-model='policy'>
    <!--<div @click="enregistrerCookie" v-show="nom!='' && short!='' && domain!='' && policy!=''">
        <a href="#">Enregistrer mon cookie</a>
    </div>-->
    <div @click="enregistrerCookie" class="boutton_container">
        <div class="boutton_offre" :class="{ boutton_offre_popup : revele }">
            <p class="texte_boutton_offre">{{ page_addCookie().bouton }}</p>
        </div>
    </div>
</template>

<script>
import page_add from '@/json/page_addcookie.json'

export default {
  name: 'Addcookie',
    props:["revele"],
    emits: ["ajoutercookie"],
    data(){
        return{
            page_add: page_add,
            nom:"",
            short:"",
            long:"",
            domain:"",
            policy:"",
        }
    },
    methods:{
        page_addCookie(){
            return page_add[this.$store.state.codeLangue]
        },
        enregistrerCookie(){
            this.revelePopup="1"
            //On indique quon hide le boutton
            this.$emit('ajoutercookie', this.nom, this.short, this.long, this.domain, this.policy)
            this.nom=""
            this.short=""
            this.long=""
            this.domain=""
            this.policy=""
        }

    }
}
</script>

<style scoped>

.obligatoire{
    display: flex;
    justify-content: space-between;
}

.boutton_container{
    margin-bottom: 32px;
    display: flex;
    justify-content: space-around;
    margin-top: 39px;
}

.boutton_offre{
    position:inherit;
}

.boutton_offre_popup{
  transform: none;
  transition: transform 0s;
}

input{
    background-color: transparent;
    border: 1px solid #000000;
    width:100%;
    border-radius: 5px;
    padding-top: 21px;
    padding-left: 8px;
    padding-bottom: 6px;
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}
</style>