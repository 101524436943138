<template>
    <div>
        <a href='#' v-show="layout == 'category'" @click="verif">{{ page_modifcookie().title }}</a>
    </div>
</template>

<script>
import page_mod from '@/json/page_modifcookie.json'
export default {
  name: 'Modifiercookies',
    props:["layout"],
    emits: ["hideorshow"],
    data(){
        return{
            page_mod: page_mod,
        }
    },
    methods:{
        page_modifcookie(){
            return page_mod[this.$store.state.codeLangue]
        },
        verif(){
            this.$emit("hideorshow")
        }                
    }
}
</script>