<template>
    <p @click="ajouterCookie">
        <span class="spanFavicon favicon"><img class="height25" :src="icon"><div></div></span>
        <span class="spanFavicon">
            <span>{{ nomcookie }}</span><br/>
            <span class="short">{{ short }}</span>
        </span>
    </p>
</template>

<script>
export default {
  name: 'Cookie',
    props:['nomcookie', 'short', 'icon'],
    emits: ['addcookie'],
    methods:{
        ajouterCookie(){
            this.$emit('addcookie')
        }
    }
}
</script>

<style scoped src="../css/cookies.css"></style>

<style scoped>

.height25{
    height: 25px;
}

</style>