<template>
    <div id="aide_box_follow" class="aide_box_follow" v-show="help_asked==true">
        <div @click="close_help" class="fa exit_help styleFaviconsHelp">&#xf00d;</div>
        <div id="aide_box_follow_text" class="sous_tire_ecran"></div>
        <div class="nav_help">
            <div class="fa styleFaviconsHelp arrow_help" :class="[ indexHelp==0 ? ['notAllowed'] : '' ]" id="aide_box_follow_minus" @click="indexHelp=indexHelp-1">&#xf060;</div>
            <div class="fa styleFaviconsHelp arrow_help" :class="[ indexHelp+1==this.help_cookies().help.length ? ['notAllowed'] : '' ]" id="aide_box_follow_plus" @click="indexHelp=indexHelp+1">&#xf061;</div>
        </div>
    </div>
    <div class="cookies_help_button" @click="$emit('modifier_cookies_help', true), help_asked=true">Help</div>
    <div class="first_div" v-if="start">
        <div class="app">
            <div class="left left_screen">
                <div class="container_titre_inter">
                    <h2 class="titre_inter" :class="[ getActivePartie()=='config' ? 'categorieEnCours' : 'categories' ]">{{ page_cookies().title1 }}</h2>
                    <div class="fa help_interrogation_button" @click="openIndexHelp('ecran_left_general')">&#xf059;</div>
                </div>
                <div class="ecran" v-on:mouseover="setCategorieEnCours('config')" v-on:mouseleave="setCategorieEnCours('')">
                    <div v-for="(ecran, indexEcran) in ax.steps" :key="indexEcran" v-show="indexEcran==idEcran">
                        <div name="ecran_left_general" class="ecran_left_general">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc1.form.p1 }}</div>
                            <input size="30" type="text" v-model='ecran.topTitle'>
                            <div class="sous_tire_ecran">{{ page_cookies().bloc1.form.p2 }}</div>
                            <input type="text" v-model="ecran.title">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc1.form.p3 }}</div>
                            <input size="30" type="text" v-model='ecran.subTitle'>
                            <div class="sous_tire_ecran">{{ page_cookies().bloc1.form.p4 }}</div>
                            <textarea size="150" v-model="ecran.message"></textarea>
                        </div>

                        <div name="modifier_cookies_button" class="sous_titre_bleu" v-show="ax.steps[idEcran].layout == 'category'">
                            <span class="sous_titre_bleu_1" @click="hideOrShowCookies">{{ page_cookies().bloc1.sub1 }}</span>
                            <i @click="hideOrShowCookies" class="fas angle_right" :class="{ angle_down : modifierCookies}">&#xf105;</i>
                        </div>

                        <div class="all_cookies_from_screen" v-show="modifierCookies">
                            <div class="sous_tire_ecran_gras">{{ page_cookies().bloc1.bloc2.sub1 }}</div>
                            <Cookie class="miniCookie in" @addcookie="incrementArray(0, 0, ax.steps[idEcran].vendors, indexCookie)" v-for="(cookie, indexCookie) in ax.steps[idEcran].vendors" :nomcookie="cookie['title']" :short="cookie['shortDescription']" :icon="cookie['favicon']" :key="cookie['_id']" />
                            <div name="ajouter_cookie_perso" class="sous_titre_bleu">
                                <span class="sous_titre_bleu_1" @click="definirCategorie('ajout')">{{ page_cookies().bloc1.sub2 }}</span>
                                <i @click="definirCategorie('ajout')" class="fas angle_right" :class="{ angle_down : categorieCourante=='ajout'}">&#xf105;</i>
                            </div>
                            <div class="ecran_left_general" v-show="categorieCourante=='ajout'">
                                <Addcookie :revele='revele' @ajoutercookie="ajouterCookie" />
                            </div>
                            <div name="categories_cookies">
                                <div class="sous_tire_ecran_gras">{{ page_cookies().bloc1.bloc2.sub2 }}</div>
                                <div class="propositions_categories">
                                    <span :class="{ 'categorieEnCours' : underlineCategorie(categorie.id) }" class="categories_conf" v-on:click="definirCategorie(categorie['id'])" v-for="(categorie, indexCategorie) in typesCookies" :key="indexCategorie">{{ categorie["type"] }}</span>
                                    <!--<span :class="{ 'categorieEnCours' : underlineCategorie('ajout') }" class ="categories" v-on:click="displayAjout">Ajouter un cookie</span>-->
                                </div>
                            </div>
                            <Cookie class="miniCookie out" @addcookie="incrementArray(ax.steps[idEcran].vendors, cookie)" v-for="cookie in listIdCookies" :nomcookie="cookie['title']" :key="cookie['_id']" :short="cookie['shortDescription']" :icon="cookie['favicon']" v-show="!(listIdMesCookies(ax.steps).includes(cookie['_id'])) && cookie['type']==categorieCourante" />
                        </div>
                    </div>
                    <div name="barreOutilsEcran" class="barreOutilsEcran">
                        <Precedent @ecranprecedent="decrementer" :class="[ idEcran!=0 ? ['allGood', 'nextAndPrev'] : 'notAllowed' ]" />
                        <div class="barreModif">
                            <Newecran class="addAndDelete allGood addHover" @ajouterecran="ajouterEcran(idEcran+1)" />
                            <Deleteecran class="addAndDelete" :class="[ idEcran!=0 ? ['trash', 'allGood'] : 'notAllowed' ]" @supprimerecran="supprimerEcran(idEcran)" />
                        </div>
                        <Suivant @ecransuivant="incrementer" :class="[ idEcran!=ax.steps.length-1 ? ['allGood', 'nextAndPrev'] : 'notAllowed']" />
                    </div>
                    <div class="fa help_interrogation_button flex_centered" @click="openIndexHelp('barreOutilsEcran')">&#xf059;</div>
                </div>
            </div>
            <div class="right">
                <h2 class="titre_inter" :class="[ getActivePartie()=='visu' ? 'categorieEnCours' : 'categories' ]">{{ page_cookies().title2 }}</h2>
                <div name="visualisation" v-on:mouseover="setCategorieEnCours('visu')" v-on:mouseleave="setCategorieEnCours('')" class="presentation" :style="{ backgroundColor: style['colors']['card'], borderRadius: style['widgetStyle']['borderRadius'] + 'px' }">
                    <div class="logo">
                        <img class="imgLogo" id="urlLogo" :src="ax['steps'][0]['image']">
                    </div>
                    <div id="box" name="box" class="box">
                        <div class="surtitre" v-show="ax.steps[idEcran].topTitle!=''" :style="{ color: style['colors']['title'] }">{{ ax.steps[idEcran].topTitle }}</div>
                        <div class="titre" v-show="ax.steps[idEcran].title!=''" :style="{ color: style['colors']['title'] }">{{ ax.steps[idEcran].title }}</div>
                        <div class="surtitre" v-show="ax.steps[idEcran].subTitle!=''" :style="{ color: style['colors']['title'] }">{{ ax.steps[idEcran].subTitle }}</div>
                        <div class="text" v-show="ax.steps[idEcran].message!=''" :style="{ color: style['colors']['text'] }">{{ ax.steps[idEcran].message }}</div>

                        <div class="checkPart" v-show="ax.steps[idEcran].layout=='category' && ax.steps[idEcran].vendors.length>0">
                            <div class="toutCocher" v-show="ax.steps[idEcran].vendors.length>1">
                                <strong>{{ page_cookies().bloc2.checkAll }}</strong>
                                <div class="switch">
                                    <span class="slider"></span>
                                </div>
                            </div>
                            <div class="lesCookies">
                                <div class="leCookie" v-for="(cookie, indexCookie) in ax.steps[idEcran].vendors" :key="indexCookie">
                                    <div class="leCookieDisplay">
                                        <div class="leCookieIcon">
                                            <img class="height16" :src="cookie['favicon']">
                                        </div>
                                        <div>
                                            <div class="leCookieNom">{{ cookie.name }}</div>
                                            <div class="leCookieDesc">{{ cookie.shortDescription }}</div>
                                        </div>
                                        <div class="miniCheck">
                                            <div class="switch2">
                                                <span class="slider2"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-show="ax.steps[idEcran].layout!='category'" class="conf"><a :href="ax['steps'][0]['policyUrl']" :style="{ color: style['colors']['text'] }">{{ page_cookies().bloc2.conf }}</a></div>
                    </div>
                    <div id="groupbutton" class="groupbutton" v-if="idEcran==0 & ax.steps.length>1">
                        <button class="button1">{{ ax.strings['dismiss'] }}</button>
                        <button class="button2" :style="{ color: style['colors']['text'] }" v-on:click="incrementer">{{ ax.strings['configure'] }}</button>
                        <button class="button3 buttongras" :style="{ color: style['colors']['title'] }">{{ ax.strings['acceptAll'] }}</button>
                    </div>
                    <div id="groupbutton" class="groupbutton" v-if="idEcran!=0 && idEcran!=ax.steps.length-1">
                        <button class="button1" v-on:click="decrementer">{{ ax.strings['prevStep'] }}</button>
                        <button class="button2" :style="{ color: style['colors']['text'] }" v-on:click="incrementer">{{ ax.strings['acceptAllAndNext'] }}</button>
                        <button class="button3 buttongras" :style="{ color: style['colors']['title'] }" v-show="idEcran!=ax.steps.length-1" v-on:click="incrementer">{{ ax.strings['nextStep'] }}</button>
                    </div>
                    <div id="groupbutton" class="groupbutton" v-if="idEcran==ax.steps.length-1">
                        <button class="button1" v-on:click="decrementer">{{ ax.strings['prevStep'] }}</button>
                        <button class="button2" :style="{ color: style['colors']['text'] }">{{ ax.strings['acceptAllAndNext'] }}</button>
                        <button class="button3 buttongras" :style="{ color: style['colors']['title'] }">{{ ax.strings['lastStep'] }}</button>
                    </div>
                </div>
                <div class="container_titre_inter">
                    <h2 class="titre_inter" :class="[ getActivePartie()=='perso' ? 'categorieEnCours' : 'categories' ]">{{ page_cookies().title3 }}</h2>
                    <div class="fa help_interrogation_button" @click="openIndexHelp('pesonnalisation')">&#xf059;</div>
                </div>
                <div name="pesonnalisation" v-on:mouseover="setCategorieEnCours('perso')" v-on:mouseleave="setCategorieEnCours('')" class="presentation">
                    <div class="box pesonnalisation">
                        <div class="propositions" :class="{'propositions_no_margin' : !personnalisation}">
                            <div :class="{ 'categorieEnCours' : personnalisation=='couleurs' }" class="categories" v-on:click="categoriePerso('couleurs')">{{ page_cookies().bloc3.sub1 }}</div>
                            <div :class="{ 'categorieEnCours' : personnalisation=='boutons' }" class="categories" v-on:click="categoriePerso('boutons')">{{ page_cookies().bloc3.sub2 }}</div>
                            <div :class="{ 'categorieEnCours' : personnalisation=='perso' }" class="categories" v-on:click="categoriePerso('perso')">{{ page_cookies().bloc3.sub3 }}</div>
                        </div>
                        <div class="couleursBox" v-if="personnalisation=='couleurs'">
                            <div>{{ page_cookies().bloc3.bloc1.p1 }}</div>
                            <input class="couleurs" type="color" v-model="style['colors']['title']">
                            <div>{{ page_cookies().bloc3.bloc1.p2 }}</div>
                            <input class="couleurs" type="color" v-model="style['colors']['text']">
                            <div>{{ page_cookies().bloc3.bloc1.p3 }}</div>
                            <input class="couleurs" type="color" v-model="style['colors']['card']">
                        </div>
                        <div v-if="personnalisation=='boutons'" class="fit_content">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc2.p1 }}</div>
                            <input type='text' v-model="ax.strings['nextStep']">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc2.p2 }}</div>
                            <input type='text' v-model="ax.strings['prevStep']">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc2.p3 }}</div>
                            <input type='text' v-model="ax.strings['configure']">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc2.p4 }}</div>
                            <input type='text' v-model="ax.strings['dismiss']">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc2.p5 }}</div>
                            <input type='text' v-model="ax.strings['acceptAll']">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc2.p6 }}</div>
                            <input type='text' v-model="ax.strings['lastStep']">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc2.p7 }}</div>
                            <input type='text' v-model="ax.strings['acceptAllAndNext']">
                        </div>
                        <div v-if="personnalisation=='perso'" class="fit_content">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc3.p1 }}</div>
                            <input type="text" v-model="ax['steps'][0]['image']">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc3.p2 }}</div>
                            <input type="text" v-model="ax['steps'][0]['policyUrl']">
                            <div class="sous_tire_ecran">{{ page_cookies().bloc3.bloc3.p3 }}</div>
                            <input type="number" v-model="style['widgetStyle']['borderRadius']" style="width: 40px;" min="0" max="100">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="start" class="boutton_container">
            <div name="enregistrer_cookies" @click="enregistrerCookie()" class="boutton_offre" :class="{ boutton_offre_popup : revele }">
                <p class="texte_boutton_offre">{{ page_cookies().enregistrer }}</p>
            </div>
        </div>
        <!--<div v-show="start" @click="enregistrerCookie()">Enregistrer mes cookies</div>-->
        <div class="cookiesFooterInfos">
            <div class="left code_full_container">
                <div name="registre_container">
                    <div class="flex">
                        <div class="sous_tire_ecran_gras">{{ page_cookies().footer.bloc1.title }}</div>
                        <div class="fa help_interrogation_button" @click="openIndexHelp('registre_container')">&#xf059;</div>
                    </div>
                    <div class="flex dateContainer">
                        <div class="dateLeft">
                            <div>{{ page_cookies().footer.bloc1.p1 }}</div>
                            <input v-model="startDate" class="inputDate" type="date">
                        </div>
                        <div>
                            <div>{{ page_cookies().footer.bloc1.p2 }}</div>
                            <input v-model="endDate" class="inputDate" type="date">
                        </div>
                    </div>
                    <div class="copierCodeContainer" @click="loadConsentementCookies">{{ page_cookies().footer.bloc1.bouton }}</div>
                </div>
            </div>
            <div class="left code_full_container">
                <div name="copy_container">
                    <div class="flex">
                        <div class="sous_tire_ecran_gras">{{ page_cookies().footer.bloc2.title }}</div>
                        <div class="fa help_interrogation_button" @click="openIndexHelp('copy_container')">&#xf059;</div>
                    </div>
                    <div class="flex">
                        <pre ref="code" class="code_rectangle">
window.axeptioSettings = {
    clientId: "{{ projectId }}",
};
(function(d, s) {
    var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
    e.async = true; e.src = "//static.axept.io/sdk.js";
    t.parentNode.insertBefore(e, t);
})(document, "script");</pre>
                        <div class="copy_far_container">
                            <div class="far copy_far" @click="copy($refs.code)">&#xf0c5;</div>
                        </div>
                    </div>
                    <div class="copierCodeContainer" @click="copy($refs.code)">
                        <span v-if="textCopied==false">{{ page_cookies().footer.bloc2.bouton.before }}</span>
                        <span v-else>{{ page_cookies().footer.bloc2.bouton.after }}</span>
                    </div>
                </div>
            </div>
            <div class="left code_full_container">
                <div name="nomenclature_container">
                    <div class="flex">
                        <div class="sous_tire_ecran_gras">{{ page_cookies().footer.bloc3.title }}</div>
                        <div class="fa help_interrogation_button" @click="openIndexHelp('nomenclature_container')">&#xf059;</div>
                    </div>
                    <div v-for="(step, indexStep) in ax.steps" :key="indexStep">
                        <div v-for="(cookie, indexCookie) in step.vendors" :key="indexCookie">{{ cookie.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Suivant from '@/components/Suivant.vue'
import Precedent from '@/components/Precedent.vue'
import Modifiercookies from '@/components/Modifiercookies.vue'
import Cookie from '@/components/Cookie.vue'
import Newecran from '@/components/Newecran.vue'
import Deleteecran from '@/components/Deleteecran.vue'
import Addcookie from '@/components/Addcookie.vue'
import Modale from '@/components/Modale.vue'
import json_config from "@/json/config.json";
import list_cookies from "@/json/listCookies.json";
import page_pop from '@/json/page_popup.json'
import page_all from '@/json/page_allcookies.json'
import helpCookies from '@/json/help_cookies.json'
export default {
    name: 'Cookies',
    props:["cookieId", "projectId", 'revele'],
    components: {
        Suivant, Precedent, Modifiercookies, Cookie, Newecran, Deleteecran, Addcookie, Modale
    },
    emits: ["deployerpopup", "modifier_cookies_help"],
    data() {
        return {
            page_all: page_all,
            page_pop: page_pop,
            helpCookies: helpCookies,
            idEcran: 0,
            //ax: {steps:[]},
            ax:{steps:[{image:""}]},
            listeCookies:[],
            modifierCookies: false,
            categorieCourante: "",
            style: {colors:{title:"", text:""}, customButton:{colors:{card: ""}}, widgetStyle:{borderRadius:""}},
            personnalisation: "",
            partieEnCours: "",
            start: false,
            textCopied: false,
            consentementCookiesUrl:"",
            startDate:"",
            endDate:"",
            help_asked: false,
            indexHelp: 0
        }
    },
    methods:{
        page_cookies(){
            return page_all[this.$store.state.codeLangue]
        },
        page_popup(){
            return page_pop[this.$store.state.codeLangue]
        },
        help_cookies(){
            return helpCookies[this.$store.state.codeLangue]
        },
        page_popup_global(){
            return page_pop.global
        },
        openIndexHelp(nameParam){
            var nbHelps = this.help_cookies().help.length
            for(var i = 0; i<nbHelps; i++){
                if(this.help_cookies().help[i].name==nameParam){
                    this.idEcran=1
                    this.indexHelp=i
                    this.$emit('modifier_cookies_help', true)
                    this.help_asked=true
                    i=nbHelps
                }
            }
        },
        changeHelpPart(boolParam){
            this.idEcran=1
            if(this.help_asked){
                var nbHelp = this.help_cookies().help.length
                var currentRef = this.help_cookies().help[this.indexHelp].name
                //Si il faut effectuer un clic
                if(this.help_cookies().help[this.indexHelp].hasOwnProperty("open") && boolParam){
                    //Si l'attribut est personnalisation
                    if(this.help_cookies().help[this.indexHelp].open=="personnalisation"){
                        this.personnalisation = this.help_cookies().help[this.indexHelp].param
                    }
                    else if(this.help_cookies().help[this.indexHelp].open=="modifierCookies"){
                        this.modifierCookies = this.help_cookies().help[this.indexHelp].param
                    }
                }
                if(this.help_cookies().help[this.indexHelp].considerScreen){
                    var currentRefDOM = document.getElementsByName(currentRef)[this.idEcran]
                }
                else{
                    var currentRefDOM = document.getElementsByName(currentRef)[0]
                }
                currentRefDOM.classList.add("aide_shiny_part");
                if(this.help_cookies().help[this.indexHelp].more_space){
                    currentRefDOM.classList.add("aide_shiny_part_space");
                }
                if(this.indexHelp>0 && this.help_cookies().help[(this.indexHelp-1)].name!=this.help_cookies().help[this.indexHelp].name){
                    var prevRef = this.help_cookies().help[(this.indexHelp-1)].name
                    if(this.help_cookies().help[(this.indexHelp-1)].considerScreen){
                        var prevRefDOM = document.getElementsByName(prevRef)[this.idEcran]
                    }
                    else{
                        var prevRefDOM = document.getElementsByName(prevRef)[0]
                    }
                    prevRefDOM.classList.remove("aide_shiny_part");
                    prevRefDOM.classList.remove("aide_shiny_part_space");
                }
                //Si on a un apres on remove la classe
                if(this.indexHelp+1<nbHelp && this.help_cookies().help[(this.indexHelp+1)].name!=this.help_cookies().help[this.indexHelp].name){
                    var nextRef = this.help_cookies().help[(this.indexHelp+1)].name
                    if(this.help_cookies().help[(this.indexHelp+1)].considerScreen){
                        var nextRefDOM = document.getElementsByName(nextRef)[this.idEcran]
                    }
                    else{
                        var nextRefDOM = document.getElementsByName(nextRef)[0]
                    }
                    if(typeof nextRefDOM!=="undefined"){
                        nextRefDOM.classList.remove("aide_shiny_part");
                        nextRefDOM.classList.remove("aide_shiny_part_space");
                    }
                }
                var waitForStepBack = false
                document.getElementById("aide_box_follow_text").textContent=this.help_cookies().help[this.indexHelp].text
                var box_base = document.getElementById("aide_box_follow")
                currentRefDOM.parentNode.insertBefore(box_base, currentRefDOM.nextSibling);
                var options={
                    top: currentRefDOM.offsetTop-168,
                    left: 0,
                    behavior: 'smooth'
                }
                window.scrollTo(options)
            }
        },
        close_help(){
            var currentRef = this.help_cookies().help[this.indexHelp].name
            if(this.help_cookies().help[this.indexHelp].considerScreen){
                var currentRefDOM = document.getElementsByName(currentRef)[this.idEcran]
            }
            else{
                var currentRefDOM = document.getElementsByName(currentRef)[0]
            }
            currentRefDOM.classList.remove("aide_shiny_part");
            currentRefDOM.classList.remove("aide_shiny_part_space");
            this.help_asked=false
            this.$emit('modifier_cookies_help', false)
        },
        loadConsentementCookies(){
            var param = ""
            if(this.startDate<this.endDate){
                if(this.startDate){
                    param = param + "&start=" + Date.parse(this.startDate)
                }
                if(this.endDate){
                    param = param + "&end=" + Date.parse(this.endDate)
                }
            }
            window.location = this.consentementCookiesUrl + param;
        },
        copy(selector){
            this.textCopied = true
            var tempInput = document.createElement("textarea");
            tempInput.style.height="1px";
            tempInput.style.padding="0px";
            tempInput.style.position="absolute";
            tempInput.style.bottom="0px";
            tempInput.value = unescape(selector.textContent);
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
        },
        incrementer(){
            if(this.idEcran != this.ax.steps.length-1){
                if(window.scrollY>350){
                    //Si on a un scroll de plus de 500px;
                    var options={
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    }
                    window.scrollTo(options)
                }
                this.modifierCookies = false
                this.categorieCourante=""
                this.idEcran++;
            }
        },
        decrementer(){
            if(this.idEcran>0){
                if(window.scrollY>350){
                    //Si on a un scroll de plus de 500px;
                    var options={
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    }
                    window.scrollTo(options)
                }
                this.modifierCookies = false
                this.categorieCourante=""
                this.idEcran--;
            }
        },
        incrementArray(arr, cookie, arr2, index){
            if(arr2 !== 'undefined' && typeof index !== 'undefined'){
                arr2.splice(index, 1)
            }
            else{
                arr.push(cookie)
            }
        },
        
        listIdMesCookies(ar){
            var monArray = []
            ar.forEach(
                element => element.vendors.forEach(
                    element2 => monArray.push(element2["_id"])
                )
            )
            return monArray
        },
        hideOrShowCookies(){
            if(this.modifierCookies){
                this.modifierCookies = false
            }
            else{
                this.modifierCookies = true
            }
        },
        definirCategorie(cat){
            if(cat==this.categorieCourante){
                this.categorieCourante=""
            }
            else{
                this.categorieCourante = cat
            }
        },
        categoriePerso(cat){
            if(cat==this.personnalisation){
                this.personnalisation=""
            }
            else{
                this.personnalisation = cat
            }
        },
        ajouterEcran(index){
            this.ax.steps.splice(index, 0, {layout: "category", vendors: []})
            this.modifierCookies = false
            this.categorieCourante=""
            this.idEcran++;
        },
        supprimerEcran(index){
            this.$emit('deployerpopup', true, this.page_popup_global().info.code, "cookies_info_screen_deleted", "cookies_info_screen_deleted")
            if(this.idEcran!=0){
                this.ax.steps.splice(index, 1)
                this.modifierCookies = false
                this.categorieCourante=""
                if(this.idEcran!=0){
                    this.idEcran--
                }
            }
        },
        displayAjout(){
            if(this.categorieCourante!='ajout'){
                this.categorieCourante='ajout'
            }
            else{
                this.categorieCourante=""
            }
        },
        ajouterCookie(nom, short, long, dom, policy){
            if(nom!='' && short!='' && dom!='' && policy!=''){
                var fav="https://icons.duckduckgo.com/ip3/"+dom+".ico"
                this.ax.steps[this.idEcran].vendors.push({title: nom, shortDescription: short, longDescription: long, domain: dom, policyUrl: policy, favicon: fav})
                this.$emit('deployerpopup', true, this.page_popup_global().info.code, "cookies_info_cookie_enregistre", "cookies_info_cookie_enregistre")
            }
            else{
                this.$emit('deployerpopup', true, this.page_popup_global().echec.code, "cookies_echec_form_not_completed", "cookies_echec_form_not_completed")
            }
        },
        underlineCategorie(cat){
            if(this.categorieCourante==cat){
                return true
            }
            else{
                return false
            }
        },
        couleurSuivant(){
            if(this.idEcran != this.ax.steps.length-1){
                return "black";
            }
            else{
                return "grey";
            }
        },
        getActivePartie(){
            return this.partieEnCours
        },
        setCategorieEnCours(cat){
            this.partieEnCours=cat
        },
        enregistrerCookie(){
            //On send le modale
            this.$emit('deployerpopup', true, this.page_popup_global().chargement.code, "cookies_chragement_data_load", "cookies_chragement_data_load")
            var part1=false;
            var part2=false
            this.style.colors.widget = this.style.colors.card
            this.style.colors.button_bg = this.style.colors.card
            this.style.colors.button_bg_hover = this.style.colors.card
            this.style.colors.primary_button_bg = this.style.colors.card
            this.style.colors.primary_button_bg_hover = this.style.colors.card
            this.style.colors.consent_button_bg = this.style.colors.card
            this.style.colors.consent_button_bg_hover = this.style.colors.card
            var mesDonnees = new FormData();
            mesDonnees.set("cookieId", this.cookieId);
            mesDonnees.set("ax", JSON.stringify(this.ax));
            mesDonnees.set("axeptio", "sendCookies");
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/axeptio.php',
                data: mesDonnees
            })
            .then((result) => {
                console.log(result)
                //Si part1 ok
                if(result.data.id){
                
                    var mesDonnees = new FormData();
                    mesDonnees.set("projectId", this.projectId);
                    mesDonnees.set("ax", JSON.stringify(this.style));
                    mesDonnees.set("axeptio", "sendCookies");
                    axios({
                        method: 'post',
                        url: json_config.phpFiles_url + '/axeptio.php',
                        data: mesDonnees
                    })
                    .then((result) => {
                        console.log(result)
                        if(result.data.id){
                            this.$emit('deployerpopup', true, this.page_popup_global().info.code, "cookies_info_all_done", "cookies_info_all_done")
                        }
                        else{
                            this.$emit('deployerpopup', true, this.page_popup_global().echec.code, "cookies_echec_partially_done", "cookies_echec_partially_done")
                        }
                    })
                }
                else{
                    //Sinon on envoie un popup
                    this.$emit('deployerpopup', true, this.page_popup_global().echec.code, "cookies_echec_not_done", "cookies_echec_not_done")
                }
            })
        }
    },
    watch:{
        indexHelp(){
            this.changeHelpPart(true)
        },
        help_asked(){
            this.changeHelpPart(true)
        }
    },
    created(){
        var mesDonnees = new FormData();
        mesDonnees.set("cookieId", this.cookieId);
        mesDonnees.set("axeptio", "cookieId");
        axios({
            method: 'post',
            url: json_config.phpFiles_url + '/axeptio.php',
            data: mesDonnees
        })
        .then((result)=> result.data)
        .then((result)=> {
            this.ax = result;
            //console.log(result)
            this.start=true
            this.$emit('affichercookies')
        })
    },
    
    beforeCreate(){
        /*
        fetch('../json/listCookies.json')
            .then(response => response.text())
            .then(data => {
                data = JSON.parse(data)
                this.listeCookies = data
                data.forEach(
                    element => {this.typesCookies.push({type: element["type"], id: element["id"]}),
                    element["listeCookies"].forEach(
                    element2 => this.listIdCookies.push({_id: element2["_id"], name: element2["name"], title: element2["title"], shortDescription: element2['shortDescription'], longDescription: element2['longDescription'], domain: element2['domain'], type: element2["type"], policyUrl: element2['policyUrl'], favicon: element2['favicon']})
                    )}       
                )
            }
        );*/
        this.listeCookies = list_cookies
        this.typesCookies = []
        this.listIdCookies = []
        list_cookies.forEach(
            element => {this.typesCookies.push({type: element["type"], id: element["id"]}),
            element["listeCookies"].forEach(
                element2 => this.listIdCookies.push({_id: element2["_id"], name: element2["name"], title: element2["title"], shortDescription: element2['shortDescription'], longDescription: element2['longDescription'], domain: element2['domain'], type: element2["type"], policyUrl: element2['policyUrl'], favicon: element2['favicon']})
            )}       
        )
        var mesDonnees = new FormData();
        mesDonnees.set("projectId", this.projectId);
        mesDonnees.set("axeptio", "projectId");
        axios({
            method: 'post',
            url: json_config.phpFiles_url + '/axeptio.php',
            data: mesDonnees
        })
        .then((result) => {
                if(result.data.name){
                this.style = result.data;
            }
        })
        var mesDonnees = new FormData();
        mesDonnees.set("projectId", this.projectId);
        mesDonnees.set("axeptio", "consentementUrl");
        axios({
            method: 'post',
            url: json_config.phpFiles_url + '/axeptio.php',
            data: mesDonnees
        })
        .then((result) => {
            this.consentementCookiesUrl = result.data
        })
    },
    mounted(){
        this.$store.state.cliquable=true
    }
}
</script>

<style scoped src="../css/cookies.css"></style>
<style scoped>
.arrow_help, .help_interrogation_button{
    color: #022873
}
.styleFaviconsHelp, .help_interrogation_button{
    font-size:14px;
    cursor:pointer;
    transition: all .3s;
}
.styleFaviconsHelp:hover, .help_interrogation_button{
    transform: scale(1.1)
}
.cookies_help_button{
    position: fixed;
    bottom: 30px;
    right: 30px;
    border: 1px solid #000;
    border-radius: 30px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fff;
    cursor:pointer;
    transition: all .3s
}
.cookies_help_button:hover{
    transform: scale(1.1)
}
.aide_box_follow{
    width: fit-content;
    min-width: 150px;
    max-width: 250px;
    z-index: 1;
    position: absolute;
    background-color: #fff;
    position: absolute;
    padding: 30px;
    margin-top: 80px;
    border-radius:5px;
    filter: drop-shadow(2px 2px 5px #000);
}
.aide_shiny_part{
    z-index: 1;
    background: #fff;
    position: relative;
    filter: drop-shadow(2px 2px 5px #000);
    pointer-events: none;
}
.aide_shiny_part_space{
    padding: 20px;
    border-radius: 5px;
}
.exit_help{
    position: absolute;
    top: 8px;
    right: 8px;
    color:#F2505D;
}
.nav_help{
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    left: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
}
.height16{
    height: 16px;
}
.dateContainer{
    margin-top: 50px;
    flex-wrap: wrap;
}
.dateLeft{
    margin-right: 50px;
}
.inputDate{
    margin-top: 8px;
    border: none;
    border-bottom: 1px solid black;
}
.cookiesFooterInfos{
    display: flex;
    justify-content: space-between;
    margin-top: 87px;
    flex-wrap: wrap;
}
.boutton_offre_popup{
  transform: none;
  transition: transform 0s;
}
.boutton_container{
    margin-top: 56px;
    display: flex;
    justify-content: space-around;
}
.boutton_offre{
    position:inherit;
}
.flex{
    display: flex;
}
.flex_centered{
    display:flex;
    justify-content: center;
}
.copy_far_container{
    margin-left: 5px;
    margin-top:5px;
    cursor: pointer;
}
.copy_far{
    transition: all .3s;
}
.copy_far:hover{
    transform: scale(1.3)
}
.copierCodeContainer{
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 183px;
    height: 40px;
    margin-top: 32px;
    width: fit-content;
    background: #022873;
    cursor: pointer;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    transition: all .3s;
}
.copierCodeContainer:hover{
    transform: scale(1.1);
}
.loinAGauche{
    position: absolute;
    left: -100%;
}
.code_full_container{
    border-left: 1px solid #CCCBCB;
    padding-left: 20px;
    margin-left: 20px;
    margin-left: 20px;
    margin-top: 40px;
}
.code_rectangle{
    border: 1px solid black;
    margin:0px;
    width: 504px;
    height: 123px;
    overflow:auto;
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.container_titre_inter{
    display: flex;
    align-items: center;
}

.titre_inter{
    border: none;
    cursor: initial;
    margin-bottom: 13px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-top: 30px;
}
.first_div{
    margin-top: 24px;
}
.left{
  text-align: left;
}
.left_screen{
    margin-right:20px;
}
.ecran_left_general{
    width: 340px;
}
.sous_titre_bleu{
    width:fit-content;
    margin-top: 48px;
    margin-bottom: 32px;
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #022873;
}
.sous_titre_bleu_1{
    padding-right: 8px;
    cursor: pointer;
}
.fas.angle_right{
    cursor: pointer
}
.angle_right{
    transform: rotate(0deg);
    transition: all .5s ease;
}
.angle_down{
    transform: rotate(90deg);
}
@media screen and (max-width: 1100px) {
    .ecran_left_general{
        width: 300px;
    }
}
@media screen and (max-width: 700px){
    .ecran{
        width: 100%;
    }
}
@media screen and (max-width: 600px){
    .code_rectangle{
        width: 280px;
        height: 90px;
    }
}
@media screen and (max-width: 550px){
    .left_screen{
        margin-right:0px;
    }
}
@media screen and (max-width: 500px){
    .presentation{
        width: 290px;
    }
}
@media screen and (max-width: 400px){
    .code_full_container{
        margin-left:0px;
    }
}
@media screen and (max-width: 350px){
    .code_full_container {
        border-left: none;
        padding-left: 0px;
    }
    .code_rectangle{
        width: 230px;
        height: 70px;
    }
    .ecran_left_general{
        width: 230px;
    }
}
</style>

<style>
.sous_tire_ecran{
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
.sous_tire_ecran_gras{
    margin-bottom:24px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
</style>