<template>
    <div style="width: 100%">
        <div id="dablock" class="dabody dajs-embedded dahide-navbar"></div>
    </div>
    <div id="iframeContainer">
        <iframe id="iframe" :src="url" frameBorder="0" height="0px" width="0px" @load="loadDablock"></iframe>
    </div>
    <div v-if="error">
        <div v-if="page_interview().messages[error].p1">
            <span>{{ page_interview().messages[error].p1 }}</span>
            <span class="link pointer" @click="$emit('error', error)">{{ page_interview().messages[error].p2 }}</span>
            <span>{{ page_interview().messages[error].p3 }}</span>
        </div>
        <div v-else>
            <span>{{ page_interview().messages[error] }}</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import json_config from "@/json/config.json";
import page_int from "@/json/page_interview.json"

export default {
    name: 'Interview',
    props:["site", "type"],
    emits: ["error"],
    data(){
        return{
            page_int:page_int,
            error:"",
            url:"",
            urlEmbed:""
        }
    },
    methods:{

        page_interview(){
            return page_int[this.$store.state.codeLangue]
        },
        loadInterview(){
            this.$store.state.cliquable=false
            this.error=""

            //On vide le content de dablock
            var dablock = document.getElementById("dablock");
            while (dablock.firstChild) {
                dablock.removeChild(dablock.firstChild);
            }

            //On enleve le script précédent
            var prevScript = document.getElementById("dablockScript")
            if(prevScript){
                prevScript.remove()
            }

            //Ces variables on été set pas docassemble, avant chaque interview elles doivent être reset
            String.prototype.daSprintf = undefined
            window.daSprintf = undefined

            if(this.type=="politique"){
                var mesDonnees = new FormData();
                mesDonnees.set("mail", this.$store.state.currentUser.mail);
                mesDonnees.set("site", this.site);
                mesDonnees.set("type", "registre");
                mesDonnees.set("verif", "true")
                axios({
                    method: 'post',
                    url: json_config.phpFiles_url + '/interview_docassemble.php',
                    data: mesDonnees
                })
                .then((result) => result.data)
                .then((result) => {
                    console.log(result)
                    if(result){
                        this.goForUrl(result)
                    }
                    else{
                        console.log(result)
                        this.error="no_registre"
                        this.$store.state.cliquable=true
                        document.title = this.page_interview().messages["politique_conf_meta_title"]
                    }
                })
            }
            else{
                this.goForUrl("")
            }
        },

        goForUrl(infosRegistre){
            var mesDonnees = new FormData();
            mesDonnees.set("mail", this.$store.state.currentUser.mail);
            mesDonnees.set("site", this.site);
            mesDonnees.set("type", this.type);
            if(infosRegistre!=="undefined" && infosRegistre!=""){
                mesDonnees.set("infosRegistre", JSON.stringify(infosRegistre));
            }
            //console.log(JSON.stringify(infosRegistre))
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/interview_docassemble.php',
                data: mesDonnees
            })
            .then((result) => result.data)
            .then((result) => {
                
                //Si on a pas encore l'url de l'iframe
                if(!this.url){
                    //Je renseigne l'url pour que l'iframe log le user
                    this.url = result

                    //On repart pour un tour
                    this.loadInterview()
                }
                //Sinon on recupere le lien derriere le lien d'autologin
                else{
                    
                    //test xml
                    var Http = new XMLHttpRequest();

                    var recaptchaScript = document.createElement('script')

                    //On change la reference this sinon le this fait reference a la requete xml
                    var this2 = this
                    Http.onreadystatechange = function() {
                        if(Http.readyState === 4 && Http.status === 200) {
                            
                            var urlEmbed = Http.responseURL + "&js_target=dablock"
                            
                            recaptchaScript.setAttribute('src', urlEmbed)
                            recaptchaScript.setAttribute('id', "dablockScript")
                            document.body.appendChild(recaptchaScript)

                            this2.$store.state.cliquable=true
                        }
                    }
                    
                    Http.open("GET", result, true);
                    Http.send();
                }

                //console.log(recaptchaScript)
                //recaptchaScript.setAttribute("crossorigin", "anonymous");
                //recaptchaScript.setAttribute("type", "text/html");
                //console.log(result + "&js_target=dablock")
            })
        },

        loadDablock(){
            console.log(this.url)
            console.log(this.urlEmbed)
            
            //Si au chargement on a l'url de l'iframe on retire l'iframe
            if(this.url){
                var iframeContainer = document.getElementById("iframeContainer")
                iframeContainer.removeChild(iframeContainer.firstChild)
            }
        },
    },
    watch:{
        type(){
            this.loadInterview()
        }
    },

    mounted(){
        this.loadInterview()
        this.$store.state.cliquable=false
    }

}
</script>

<style scoped>
.link{
    color:#42b983
}

#iframeContainer{
    width:0px;
    height:0px;
}

#dablock{
    text-align:left;
    width: 100%; 
    height: 100%;
    margin-bottom: 64px;
}

</style>

<!--Une erreur sur les classes suivantes pouraient provenir du scoped, il faudra peut etre l'enlever-->

<style>
label.datext-right {
    position: relative!important;
}

.popover{
    z-index:1;
}

.form-group.darequired .da-form-label:after {
    position: inherit;
}

.danotavailableyet {
    color: #aaaaaa;
}

i.fas.fa-caret-down {
    color: #42b983;
}

a.daclickable.nav-link.danavlink.active, .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    background-color: #42b983;
}

a.daclickable.nav-link.danavlink, a.nav-link.danavlink.inactive{
    color: #42b983; 
}

.text-info {
    color: #42b983!important;
}

.da-alert{
    display: none
}

.da-form-group.darequired .da-form-label:after {
    position: initial;
}

.btn-primary{
    background-color: #42b983!important;
    border: none!important;
    transition: all .3s!important;
}

.btn:focus, .btn-primary:focus{
    box-shadow:none!important;
}

button.btn.btn-da.btn-primary:hover{
    transform: scale(1.1)!important;
}

button.btn.btn-link.btn-da.daquestionbackbutton.danonsubmit{
    color: #42b983;
}

.btn-success{
    background-color: #42b983!important;
    border: none!important;
}

.btn-danger{
    background-color: #F2505D!important;
    border: none!important;
}

.da-attachment-email-all{
    display:none;
}

label.datext-right, div.dafieldpart, section#daquestion{
    position: inherit!important;
}

.alert {
    display: none;
}

.form-control:focus{
    border: none;
    box-shadow: 0 0 0 0.2rem rgb(66 185 131 / 35%)!important;
}
</style>