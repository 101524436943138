<template>
    <div class="suivant">
        <i @click="idEcranPlus" class="fa styleFavicons">&#xf061;</i>
    </div>
</template>

<script>
export default {
  name: 'Suivant',
    props:[],
    emits:["ecransuivant"],
    methods:{
        idEcranPlus(){
            this.$emit('ecransuivant')
        }

    }
}
</script>