<template>
    <div>
        <i @click="ajouterEcran" class="fa styleFavicons">&#xf067;</i>
    </div>
</template>

<script>
export default {
  name: 'Newecran',
    props:[],
    emits: ["ajouterecran"],
    methods:{
        ajouterEcran(){
            this.$emit("ajouterecran")
        }
    }
}
</script>