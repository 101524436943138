<template>
  <div v-if="page=='site' && cookies_help==true" class="background_cookies_help"></div>
  <div class="main">
    <div class="compte_header">
      <!--<span @click="show('abonnements')">Mes Abonnements | </span>-->
      <!--<span @click="show('infos')">Mes Infos | </span>-->
      <!--<span @click="show('registre')">Registre</span>-->
      <!--<span @click="show('factures')">Factures</span>-->
    </div>
    <!--Ici on a le popup-->
    <Modale
      :revele="revelePopup"
      :toggleModale="toggleModale"
      :title="titlePopup"
      :content="contentPopup"
      :type="typePopup"
      :sendResponse="responsePopup"
    />
    <div v-if="page=='accueil'">

      <div class="sub_header">
        <div @click="page='registre'" v-if="hasRegistre()==true">{{ page_compte().nav.t5 }}</div>
        <!--<div v-if="this.factures.length>0"><router-link to="/produits/mediation">{{ page_compte().nav.t1 }}</router-link></div>-->
        <div @click="this.$store.getters.goTo('abonnements')" >{{ page_compte().nav.t2 }}</div>
        <div @click="this.$store.getters.goTo('infos')">{{ page_compte().nav.t3 }}</div>
        <div @click="this.$store.getters.goTo('factures')">{{ page_compte().nav.t4 }}</div>
      </div>

      <div class="partie_compte" id="abonnements">
        <div class="main_title left will-fadeIn fadeFromRight">{{ page_compte().bloc1.title }}</div>
        <div class="main_title_underline will-fadeIn fadeFromRight"></div>
        <!--Si on est sur la partie abonnements-->
        <div class="partie_abonnements_content">
          <div class="no_product" v-if="abonnements===''">{{ page_compte().bloc1.loading }}</div>
          <div v-else>
            <!--Partie pour les abo valide-->
            <div class="sous_titre will-fadeIn">
              <div class="sous_titre_left">{{ page_compte().bloc1.sub1 }}</div>
              <div class="sous_titre_right"></div>
            </div>
            <div v-if="howManyAbo('active')+howManyAbo('pending-cancel')>0">
              <div class="will-fadeIn" :class="{'fadeFromRight':indexAbo%2==0}" v-for="(abonnement, indexAbo) in abonnements" :key="indexAbo">
                <div v-if="abonnement.status=='active' || abonnement.status=='pending-cancel'" class="carteAbonnement">
                  <div class="headerAbonnement">
                    <div class="carteInfo">{{ page_compte().bloc1.card.top.title }} {{ abonnement.id }}</div>
                    <div class="carteInfo">{{ abonnement.date_created.split("T")[0] }}</div>
                  </div>
                  <div v-for="(item, indexItem) in abonnement.line_items" :key="indexItem">
                    <div class="carte_nom_site">{{ item.meta_data[0].value }}</div>
                  </div>
                  <div v-if="abonnement.status=='active'" class="carte_renouvellement">{{ page_compte().bloc1.card.body.next }} {{ abonnement.next_payment_date_gmt.split("T")[0] }}</div>
                  <div class="footerAbonnement">
                    <div class="footerInfo" v-if="getArray(abonnement.meta_data, 'docassemble').length && abonnement.status=='active'" @click="chargerSite(abonnement, 'documents')">{{ page_compte().bloc1.card.boutons.b2 }}</div>
                    <div class="footerInfo" v-if="getArray(abonnement.meta_data, 'cookies').length && abonnement.status=='active'" @click="chargerSite(getArray(abonnement.meta_data, 'cookies')[0].value, 'site')">{{ page_compte().bloc1.card.boutons.b1 }}</div>
                    <div class="footerInfo" @click="chargerSite(abonnement, 'paiement')">{{ page_compte().bloc1.card.boutons.b3 }}</div>
                  </div>
                  <div class="carte_bottom_right">
                    <div class="carte_bottom_right_general">
                      <div v-if="abonnement.status=='active'">
                        <div @click="displayModale({idAbo: abonnement.id, indexAbo: indexAbo}, page_popup_global().supprimer.code)">{{ page_compte().bloc1.card.bottom.cancel }}</div>
                        <!--<div @click="annulation=abonnement.id">Annuler l'abonnement</div>-->
                        <!--<div v-show="annulation==abonnement.id">
                          <div class="red">Confirmer l'annulation</div>
                          <div @click="effectuerAnnulation(indexAbo)">Oui</div>
                          <div @click="annulation='1'">Non</div>
                        </div>-->
                      </div>
                      <div v-if="abonnement.status=='pending-cancel'">
                        <!--<div @click="restaurer(indexAbo)">Restaurer l'abonnement</div>-->
                        <div @click="displayModale({idAbo: abonnement.id, indexAbo: indexAbo}, 'restaurer')">{{ page_compte().bloc1.card.bottom.restore }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="no_product" v-else>{{ page_compte().bloc1.no_product }}</div>

            <!--Partie pour les abo en attente et expirés-->
            <div v-if="howManyAbo('on-hold')+howManyAbo('expired')>0">
              <div class="sous_titre will-fadeIn">
                <div class="sous_titre_left">{{ page_compte().bloc1.sub2 }}</div>
                <div class="sous_titre_right"></div>
              </div>
              <div class="will-fadeIn" :class="{'fadeFromRight':indexAbo%2==0}" v-for="(abonnement, indexAbo) in abonnements" :key="indexAbo">
                <div v-if="abonnement.status=='on-hold' || abonnement.status=='expired'" class="carteAbonnement">
                  <div class="headerAbonnement">
                    <div class="carteInfo">{{ page_compte().bloc1.card.top.title }} {{ abonnement.id }}</div>
                    <div class="carteInfo">{{ abonnement.date_created.split("T")[0] }}</div>
                  </div>
                  <div class="carte_nom_site" v-for="(item, indexItem) in abonnement.line_items" :key="indexItem">
                    <div>{{ item.meta_data[0].value }}</div>
                  </div>
                  <div class="footerAbonnement">
                    <div class="footerInfo" @click="chargerSite(abonnement, 'paiement')">{{ page_compte().bloc1.card.boutons.b3 }}</div>
                  </div>
                  <div class="carte_bottom_right">
                    <div class="carte_bottom_right_general">
                      <div @click="displayModale({idAbo: abonnement.id, indexAbo: indexAbo}, page_popup_global().supprimer.code)">{{ page_compte().bloc1.card.bottom.cancel }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Partie pour les abo annulés-->
            <div v-if="howManyAbo('cancelled')>0">
              <div class=" sous_titre will-fadeIn">
                <div class="sous_titre_left">{{ page_compte().bloc1.sub2 }}</div>
                <div class="sous_titre_right"></div>
              </div>
              <div class="will-fadeIn" :class="{'fadeFromRight':indexAbo%2==0}" v-for="(abonnement, indexAbo) in abonnements" :key="indexAbo">
                <div v-if="abonnement.status=='cancelled'" class="carteAbonnement">
                  <div class="headerAbonnement">
                    <div class="carteInfo">{{ page_compte().bloc1.card.top.title }} {{ abonnement.id }}</div>
                    <div class="carteInfo">{{ abonnement.date_created.split("T")[0] }}</div>
                  </div>
                  <div class="carte_nom_site" v-for="(item, indexItem) in abonnement.line_items" :key="indexItem">
                    <div>{{ item.meta_data[0].value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="separation will-fadeIn"></div>

      <div class="partie_compte" id="infos">
        <div class="main_title left will-fadeIn fadeFromRight">{{ page_compte().bloc2.title }}</div>
        <div class="main_title_underline will-fadeIn fadeFromRight"></div>
        <div class="no_product" v-if="infos==''">{{ page_compte().bloc2.loading }}</div>
        <div v-else class="partie_infos_content will-fadeIn">
          <!--Si on est sur la partie infos de l'utilisateur-->
          <!--<div v-if="toShow=='infos'">-->
            <div>
              <div class="partie_infos_content_general">
                <h3 v-show="modifInfosSucces" class="green">{{ page_compte().bloc2.modifInfosSucces }}</h3>
                <div class="partie_infos_content_border">
                  <div class="partie_infos_content_title">{{ page_compte().bloc2.bloc1.title }}</div>
                  <div v-if="toShow!='infos_modif'" class="partie_infos_content_content">
                    <div>{{ page_compte().bloc2.bloc1.l1 }} {{ infos.email }}</div>
                    <div>{{ page_compte().bloc2.bloc1.l2 }} {{ infos.last_name }}</div>
                    <div>{{ page_compte().bloc2.bloc1.l3 }} {{ infos.first_name }}</div>
                    <div>{{ page_compte().bloc2.bloc1.l4 }} {{ infos.billing.phone }}</div>
                  </div>
                  <div v-if="toShow=='infos_modif'" class="partie_infos_content_content">
                    <div class="flex">
                      <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc1.p1" v-model="infos.last_name" autocomplete="autocomplete_off_hack_xfr4!k"/>
                      <div class="spacebetween"></div>
                      <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc1.p2" v-model="infos.first_name" autocomplete="autocomplete_off_hack_xfr4!k"/>
                    </div>
                    <div class="flex">
                      <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc1.p3" v-model="infos.billing.phone" autocomplete="autocomplete_off_hack_xfr4!k"/>
                    </div>
                  </div>
                </div>
                <div class="partie_infos_content_between"></div>
                <div class="partie_infos_content_border">
                  <div class="partie_infos_content_title">{{ page_compte().bloc2.bloc2.title }}</div>
                  <div v-if="toShow!='infos_modif'" class="partie_infos_content_content">
                    <div v-if="infos.billing.company">{{ page_compte().bloc2.bloc2.l1 }} {{ infos.billing.company }}</div>
                    <div v-if="index_tva!=''">{{ page_compte().bloc2.bloc2.l2 }} {{ infos.meta_data[index_tva].value }}</div>
                    <div>{{ page_compte().bloc2.bloc2.l3 }} {{ infos.billing.address_1 }}</div>
                    <div v-if="infos.billing.address_2">{{ page_compte().bloc2.bloc2.l4 }} {{ infos.billing.address_2 }}</div>
                    <div>{{ page_compte().bloc2.bloc2.l5 }} {{ infos.billing.postcode }}</div>
                    <div>{{ page_compte().bloc2.bloc2.l6 }} {{infos.billing.city}}</div>
                    <div>{{ page_compte().bloc2.bloc2.l7 }} {{ infos.billing.country }}</div>
                    <div>{{ page_compte().bloc2.bloc2.l8 }} {{ infos.billing.phone }}</div>
                  </div>
                  <div v-if="toShow=='infos_modif'" class="partie_infos_content_content">
                    <div class="flex">
                      <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc2.p1" v-model="infos.billing.company" />
                      <div class="spacebetween"></div>
                      <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc2.p2" v-model="infos.meta_data[index_tva].value" />
                    </div>
                    <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc2.p3" v-model="infos.billing.address_1" /><br/>
                    <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc2.p4" /><br/>
                    <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc2.p5" /><br/>
                    <input type="text" :placeholder="page_compte().bloc2.placeholders.bloc2.p6" v-model="infos.billing.city" /><br/>
                    <Pays :model="infos.billing.country" @recupPays="setPays"/><br/>
                  </div>
                </div>
              </div>
              <div class="partie_infos_button">
                  <div v-if="toShow!='infos_modif'" @click="toShow='infos_modif', oldInfos=JSON.stringify(infos)" class="boutton_offre">
                      <p class="texte_boutton_offre">{{ page_compte().bloc2.boutons.modifier }}</p>
                  </div>
                  <div v-if="toShow=='infos_modif'" @click="modifierInfos1()" class="boutton_offre" :class="{ boutton_offre_popup : revelePopup }">
                      <p class="texte_boutton_offre">{{ page_compte().bloc2.boutons.enregistrer }}</p>
                  </div>
              </div>
              <div v-if="toShow=='infos_modif'" class="annulerModifs" @click="toShow='', infos=JSON.parse(oldInfos)">{{ page_compte().bloc2.boutons.cancel }}</div>
              <div v-else class="annulerModifs" @click="displayModale({revele: true}, page_popup_global().supprimer.code, 1)">{{ page_compte().bloc2.boutons.delete_account }}</div>
            </div>
          <!--</div>-->
        </div>
      </div> 

      <div class="separation will-fadeIn"></div>

      <!--Si on est sur la partie factures-->
      <div class="partie_compte partie_factures" id="factures">
        <div class="main_title left will-fadeIn fadeFromRight">{{ page_compte().bloc3.title }}</div>
        <div class="main_title_underline will-fadeIn fadeFromRight"></div>
        <div class="no_product" v-if="factures===''">{{ page_compte().bloc3.loading }}</div>
        <div v-else class="partie_factures_content">
          <div v-if="factures.length>0">
            <div v-for="(facture, keyFacture) in factures" :key="keyFacture">
              <div class="partie_factures_facture will-fadeIn" :class="{'fadeFromRight':keyFacture%2==0}" v-if="keyFacture<maxFactures">
                <div>{{ page_compte().bloc3.start }} {{ facture.date_created.split("T")[0] }}</div>
                <a class="facture" download='Facture' :href="'data:application/pdf;base64,' + facturesPdf[keyFacture]"  title='Download pdf document'>{{ page_compte().bloc3.end }}</a>
              </div>
            </div>
            <div class="partie_factures_button" v-if="minFactures<factures.length">
              <div @click="changeMaxFactures" class="boutton_offre will-fadeIn">
                <p v-if="factures.length>maxFactures" class="texte_boutton_offre">{{ page_compte().bloc3.boutons.more }}</p>
                <p v-else class="texte_boutton_offre">{{ page_compte().bloc3.boutons.less }}</p>
              </div>
            </div>
          </div>
          <div class="no_product" v-else>{{ page_compte().bloc3.no_product }}</div>
        </div>
      </div>
      <!--
      <div class="separation"></div>

      <div class="partie_compte" style="height:100%" id="registre">
        <div class="main_title">Mon registre</div>
        <div class="main_title_underline"></div>
        Si on est sur la partie registre
        <Interview type="registre" site=''/>
      </div>-->

    </div>

    <!--Si on est sur la partie pour modifier le paiement-->
    <div class="partie_compte partie_compte_paiement partie_compte_flex" v-if="page=='paiement'">
      <div>
        <i @click="page='accueil'" class="fas pointer arrow_left will-fadeIn">&#xf30a;</i>
      </div>
      <div class="container_global_margin">
        <div class="main_title will-fadeIn fadeFromRight">{{ page_compte().bloc4.title }}</div>
        <div class="main_title_underline will-fadeIn fadeFromRight"></div>
        <!--Si on est sur la partie registre-->
        <div class="paiement_sous_titre will-fadeIn fadeFromRight">{{ page_compte().bloc4.sub1 }} {{ aboCourant.id }}</div>
        <div class="paiement_precision will-fadeIn">{{ page_compte().bloc4.desc }} {{aboCourant.next_payment_date_gmt.split("T")[0]}}.</div>
        <div class="paiement_sous_titre will-fadeIn fadeFromRight">{{ page_compte().bloc4.sub2 }}</div>
        <div class="paiement_stripe will-fadeIn">
          <Stripe :libelle="page_compte().bloc4.bouton" :action="'update'" :abonnementId="aboCourant.id"/>
        </div>
      </div>
    </div>


    <div class="partie_compte_paiement partie_compte_flex" v-if="page=='site'" v-show="afficherCookies">
      <div>
        <i @click="page='accueil', afficherCookies=false" class="fas pointer arrow_left will-fadeIn">&#xf30a;</i>
      </div>
      <div class="container_global_margin" v-for="(site, indexSite) in aboCourant" :key="indexSite">
        <div class="main_title will-fadeIn fadeFromRight">{{ page_compte().bloc5.title }}</div>
        <div class="main_title_underline will-fadeIn fadeFromRight"></div>
        <div class="siteConcerneCookies will-fadeIn">{{ page_compte().bloc5.sub1 }} {{site.url}}</div>
        <!--<div @click="partInSite='cookies'+site.projectId">Voir les cookies</div>
        <div v-if="partInSite=='cookies'+site.projectId">-->
          <AllCookies :projectId='site.projectId' :cookieId='site.cookieId' :revele='revelePopup' @affichercookies="afficherCookies=true" @deployerpopup="displayFullModale" @modifier_cookies_help="modifier_cookies_help"/>
        <!--</div>-->
        </div>
    </div>
    <div class="partie_compte_paiement partie_compte_flex sous_titre_left" v-if="page=='site'" v-show="!afficherCookies">{{ page_compte().bloc5.loading }}</div>

    <!--Si on est sur la partie cookies
    <div v-if="toShow=='site'">
      <div v-for="(site, indexSite) in aboCourant" :key="indexSite">
        <h3>Site : {{site.url}}</h3>
        <div @click="partInSite='cookies'+site.projectId">Voir les cookies</div>
        <div v-if="partInSite=='cookies'+site.projectId">
          <AllCookies :projectId='site.projectId' :cookieId='site.cookieId' @deployerpopup="displayFullModale"/>
        </div>
      </div>
    </div>-->

    <!--Si on est sur la partie documents pour un site-->
    <!--
    <div style="height:100%" v-if="toShow=='documents'">
      <div style="height:100%" v-for="(item, keyItem) in aboCourant.line_items" :key="keyItem">
        <h3>{{ item.meta_data[0].value }}</h3>
        <div @click="partInSite='document', typeDocument='cgv'">CGV</div>
        <div @click="partInSite='document', typeDocument='politique'">Politique de confidentialité</div>
        <Interview v-if="partInSite=='document'" :type="typeDocument" :site="item.meta_data[0].value" />
      </div>
    </div>-->

    <!--Si on est sur la partie documents pour un site-->
    <div class="partie_compte_paiement partie_compte_flex" v-if="page=='documents'">
      <div>
        <i @click="page='accueil', partInSite='', typeDocument=''" class="fas pointer arrow_left will-fadeIn">&#xf30a;</i>
      </div>
      <div class="documents_docassemble_right">
        <div class="main_title will-fadeIn fadeFromRight">{{ page_compte().bloc6.title }}</div>
        <div class="main_title_underline will-fadeIn fadeFromRight"></div>
        <div class="siteConcerneCookies will-fadeIn">{{ page_compte().bloc6.sub1 }} {{aboCourant.line_items[0].meta_data[0].value}}</div>
        <!--Si on est sur la partie documents-->
        <div class="sub_header sub_header_left will-fadeIn fadeFromRight">
          <div @click="partInSite='document', typeDocument='cgv'" :class="{underline : typeDocument=='cgv'}">{{ page_compte().bloc6.doc1 }}</div>
          <div @click="partInSite='document', typeDocument='politique'" :class="{underline : typeDocument=='politique'}">{{ page_compte().bloc6.doc2 }}</div>
        </div>
        <Interview v-if="partInSite=='document'" :type="typeDocument" :site="aboCourant.line_items[0].meta_data[0].value" @error='receveInterviewError' />
      </div>
    </div>

    <!--Si on est sur la partie registre-->
    <div class="partie_compte_paiement partie_compte_flex" v-if="page=='registre'">
      <div>
        <i @click="page='accueil', partInSite='', typeDocument=''" class="fas pointer arrow_left will-fadeIn">&#xf30a;</i>
      </div>
      <div class="documents_docassemble_right">
        <div class="main_title will-fadeIn fadeFromRight">{{ page_compte().bloc7.title }}</div>
        <div class="main_title_underline will-fadeIn fadeFromRight"></div>
        <!--Si on est sur la partie documents-->
        <Interview type="registre" site=""/>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios';
import AllCookies from '@/components/AllCookies.vue';
import Stripe from '@/components/Stripe.vue';
import Pays from '@/components/Pays.vue';
import Interview from '@/components/Interview.vue';
import Modale from '@/components/Modale.vue';
import json_config from "@/json/config.json";
import page_pop from '@/json/page_popup.json'
import page_com from '@/json/page_compte.json'
import page_glb from '@/json/page_global.json'
import fadeIn from "@/js/fadeIn.js";

//Attention, quand on cliquera sur un autre élément il faudra enlever 
export default{
  name: 'Compte',
  components: {
    AllCookies, Stripe, Pays, Interview, Modale
  },
  data(){
    return{
      page_glb: page_glb,
      page_com: page_com,
      page_pop: page_pop,
      page:"accueil",
      abonnements:"",
      toShow: "",
      sites: [],
      aboCourant: "",
      partInSite: "",
      typeDocument: "",
      annulation:"",
      infos:"",
      oldInfos:"",
      modifButton:true,
      modifInfosSucces:false,
      factures:"",
      facturesPdf:"",
      index_tva:"",
      maxFactures:3,
      minFactures:3,
      revelePopup: "",
      titlePopup:"",
      contentPopup:"",
      typePopup:"",
      functionPopup:"",
      afficherCookies:false,
      cookies_help:false,
      next_abo_id_to_increase: false
    }
  },
  methods:{
    page_global(){
      return this.page_glb[this.$store.state.codeLangue]
    },
    page_compte(){
      return this.page_com[this.$store.state.codeLangue]
    },
    page_popup(){
      return this.page_pop[this.$store.state.codeLangue]
    },
    page_popup_global(){
      return this.page_pop.global
    },
    modifier_cookies_help(bool){
      this.cookies_help=bool
    },

    receveInterviewError(errorP){
      if(errorP=="no_registre"){
        this.page='registre'
      }
    },

    getProducts(){
      //On get les produits
      axios.get(json_config.phpFiles_url + "/produits.php")
      .then((result) => result.data)
      .then((result) => {
          this.produits = result;
          console.log(result)
          this.$store.state.cliquable = true
      })  
    },

    getProductBySlug(slugP){
      for(var i=0; i<this.produits.length; i++){
        if(this.produits[i].slug==slugP){
          break;
        }
      }
      return this.produits[i]
    },

    modifierInfos1(){
        //ICI ON VERIFIE QUE LA TVA AIE CHANGEE
        //On considere que la tva a bougée
        this.$store.state.currentUser.tvaUpdated=true
        window.localStorage.currentUser=JSON.stringify(this.$store.state.currentUser)
      //On range la modification
      //this.toShow=""
      //On deploie le popup
      this.revelePopup=true;
      this.revelePopup=false;
      this.revelePopup=true;
      this.typePopup=this.page_popup_global().chargement.code
      this.titlePopup="compte_chargement_load"
      this.contentPopup="compte_chargement_load"

      var tva = this.infos.meta_data[this.index_tva].value
      //ICI ON DOIT PASSER DANS LE IF SEULEMENT SI LA TVA A ETE CHANGEE
      //Si on a une tva on la verifie
      if(tva){
        this.verifierTVA(tva)
      }
      //On met que le user paie la tva
      //sinon on envoie les infos
      else{
        this.$store.state.currentUser.tva=true
        window.localStorage.currentUser=JSON.stringify(this.$store.state.currentUser)
        //ICI ON PASSE LE BOOLEEN POUR SAVOIR SI LA TVA A BOUGEE
        this.modifierInfos2()
      }
    }, 

    verifierTVA(tva){
      var mesDonnees = new FormData();
      mesDonnees.set("tva", tva);

      axios({
          method: 'post',
          url: json_config.phpFiles_url + '/verifierTVA.php',
          data: mesDonnees
      })
      .catch((error) => {
        this.revelePopup=true
        this.typePopup=this.page_popup_global().echec.code
        this.titlePopup='compte_echec_tva_fail'
        this.contentPopup="compte_echec_tva_fail"
      })
      //.then((result) => result.data)
      .then((result) => {
          //Si on a un resultat on envoie les infos
          //On met que le user ne paie pas la tva
          if(result.data){
              this.$store.state.currentUser.tva=false
              window.localStorage.currentUser=JSON.stringify(this.$store.state.currentUser)
              //SI ON EN EST LA C'EST QUE LA TVA A CHANGEE, ON ENVOIE UN BOOL SET A TRUE
              this.modifierInfos2()
          }
          //Sinon on envoie un modale avec l'erreur
          else{
            this.revelePopup=this.page_popup_global().echec.code
            this.typePopup="echec"
            this.titlePopup='compte_echec_tva_fail'
            this.contentPopup="compte_echec_tva_fail"
          }
      })
    },

    toggleModale(){
      this.revelePopup=""
    },

    displayModale(reveleP, typeP, numberIndexP){
      this.revelePopup=reveleP
      this.typePopup=typeP

      console.log(typeP)
      console.log(this.page_popup_global().supprimer.code)
      if(typeP==this.page_popup_global().supprimer.code){
        if(numberIndexP==1){
          this.titlePopup='compte_supprimer_compte'
          this.contentPopup='compte_supprimer_compte'
        }
        else{
          this.titlePopup='compte_supprimer_abo#' + reveleP.idAbo
          this.contentPopup='compte_supprimer_abo'
        }
      }
      else if(typeP==this.page_popup_global().restaurer.code){
        this.titlePopup='compte_restaurer_abo#' + reveleP.idAbo
        this.contentPopup='compte_restaurer_abo'
      }
      else if(typeP==this.page_popup_global().info.code){
        this.titlePopup='compte_info_form'
        this.contentPopup='compte_info_form'
      }
      else if(typeP==this.page_popup_global().echec.code){
        this.titlePopup='compte_echec_form'
        this.contentPopup="compte_echec_form"
      }
    },

    displayFullModale(reveleP, typeP, titleP, contentP){
      this.revelePopup=reveleP
      this.typePopup=typeP
      this.titlePopup=titleP
      this.contentPopup=contentP
    },

    responsePopup(title){
      //Si le popup etait le popup "supprimer" alors on evoie la suppression de l'abbo
      if(title.includes("compte_supprimer_abo")){
        this.effectuerAnnulation(this.revelePopup.indexAbo)
      }

      else if(title.includes("compte_supprimer_compte")){
        console.log("on supprime le compte")
        this.deleteAccount()
      }

      else if(this.typePopup=="restaurer"){
        this.restaurer(this.revelePopup.indexAbo)
      }
    },

    changeMaxFactures(){
      if(this.maxFactures==this.factures.length){
        this.maxFactures=this.minFactures
      }
      else{
        this.maxFactures=this.factures.length
      }
    },

    howManyAbo(param){
      var nbAbo = 0
      this.abonnements.forEach(abonnement =>{
        if(abonnement.status==param){
          //Si l'abnnement est active/pending-cancelled
          nbAbo+=1
        }
      })
      return nbAbo
    },

    hasRegistre(){
      var hasRegistre = false

      if(this.abonnements!==''){
        this.abonnements.forEach(abonnement =>{
          console.log(abonnement)
          if(abonnement.meta_data.filter(res => res.key=="docassemble").length>0 && (abonnement.status=="active" || abonnement.status=="pending-cancel")){
            hasRegistre = true
          }
        })
      }
      return hasRegistre
    },

    show($param){
      this.toShow=$param
      this.partInSite=""
      this.typeDocument=""
    },

    chargerSite(arraySite, toShowParam){
      window.scrollTo(0, 0)
      this.page=toShowParam
      this.aboCourant=arraySite
    },

    getArray(array, key){
      return array.filter(res => res.key==key)
    },

    getNextAboToIncrease(param){
      var toReturn = false
      for(var i=0;i<this.abonnements.length;i++){
        if(this.abonnements[i].status==param){
          toReturn = i
          break;
        }
      }

      return toReturn
    },

    effectuerAnnulation(indexAbo){
      this.$store.state.cliquable = false
      //this.abonnements[indexAbo].status="on-hold"
      var mesDonnees = new FormData();
      mesDonnees.set("id", this.abonnements[indexAbo].id);
      mesDonnees.set("status", "pending-cancel");
      axios({
        method: 'post',
        url: json_config.phpFiles_url + '/annulation.php',
        data: mesDonnees
      })
      .then((result) => result.data)
      .then((result) => {
          console.log(this.getNextAboToIncrease("active"))
          //Si il y a une erreur
          if(result.code){
            this.displayModale(true, "echec")
            console.log("annluation ratée")
            this.$store.state.cliquable = true
          }
          //Sinon c'est ok on modifie la donnée locale
          else{
            this.displayModale(true, "info")
            this.abonnements[indexAbo].status="pending-cancel"

            //Si le slug de l'abo annulé est "multisite"
            console.log(this.getProductBySlug("mise-en-conformite"))
            console.log(this.abonnements[indexAbo].line_items[0].product_id)
            if(this.abonnements[indexAbo].line_items[0].product_id==this.getProductBySlug("mise-en-conformite").id){
              console.log("la on envoie la modif des abo")
              //On get le next id qu'on va update a multisite
              var next_abo_id_to_increase = this.getNextAboToIncrease("active")
              console.log(next_abo_id_to_increase)

              var mesDonnees = new FormData();
              mesDonnees.set("oldAboId", this.abonnements[indexAbo].id);
              mesDonnees.set("oldAboNewSlug", "multisite");
              if(next_abo_id_to_increase){
                mesDonnees.set("newAboId", this.abonnements[next_abo_id_to_increase].id);
                mesDonnees.set("newAboNewSlug", "mise-en-conformite");
              }

              axios({
                method: 'post',
                url: json_config.phpFiles_url + '/changeAboMultisite.php',
                data: mesDonnees
              })
              .then((result)=>{
                console.log(result.data)
                this.getProducts()
              })
            }
            else{
              this.$store.state.cliquable = true
            }
          }
      })
      this.annulation=""  
    },

    restaurer(indexAbo){
      var mesDonnees = new FormData();
      mesDonnees.set("id", this.abonnements[indexAbo].id);
      mesDonnees.set("status", "active");
      axios({
        method: 'post',
        url: json_config.phpFiles_url + '/annulation.php',
        data: mesDonnees
      })
      .then((result) => result.data)
      .then((result) => {
          //Si il y a une erreur
          if(result.code){
            this.displayModale(true, "echec")
            console.log("restauration ratée")
          }
          //Sinon c'est ok on modifie la donnée locale
          else{
            var nbAboActif = this.howManyAbo("active")
            this.displayModale(true, "info")
            this.abonnements[indexAbo].status="active"

            //Si aucun abonnement n'est actif
            console.log(nbAboActif)
            if(nbAboActif==0){
              var mesDonnees = new FormData();
              mesDonnees.set("newAboId", this.abonnements[indexAbo].id);
              mesDonnees.set("newAboNewSlug", "mise-en-conformite");

              axios({
                method: 'post',
                url: json_config.phpFiles_url + '/changeAboMultisite.php',
                data: mesDonnees
              })
              .then((result)=>{
                console.log(result.data)
              })
            }
          }
      })  
    },

    deleteAccount(){
      var mesDonnees = new FormData();
      mesDonnees.set("mail", this.$store.state.currentUser.mail);
      mesDonnees.set("type", "delete");
      axios({
        method: 'post',
        url: json_config.phpFiles_url + '/infosCustomer.php',
        data: mesDonnees
      })
      .then((result) => {
        console.log(result)
        this.revelePopup=false
        this.$store.state.erreurConnexion = "deleted_account"
        this.$store.dispatch('logoutUser')
        this.$store.dispatch("viderPanier")
        this.$router.push('/connexion')
      })
    },

    setPays(pays){
      this.infos.billing.country=pays
    },

    modifierInfos2(){
      this.modifButton=false
      var mesDonnees = new FormData();
      mesDonnees.set("mail", this.$store.state.currentUser.mail);
      mesDonnees.set("type", "set");
      mesDonnees.set("id", this.infos.id);
      mesDonnees.set("infos", JSON.stringify(this.infos));

      axios({
        method: 'post',
        url: json_config.phpFiles_url + '/infosCustomer.php',
        data: mesDonnees
      })
      .then((result) => {
        this.modifButton=true
        console.log(result)
        if(result.data.username){
          //SI ON RECOIT UN BOOL TRUE ALORS SA TVA A CHANGEE, ON LE DECONNECTE PUIS RECONNECTE
          this.toShow=""
          this.displayModale(true, "info")
        }
        else{
          this.displayModale(true, "echec")
        }
        //this.afficherSucces()
      })
    },

    afficherSucces(){
      this.modifInfosSucces=true
      setTimeout(()=>{
        this.modifInfosSucces=false
      }, 5000);
    },

    loadFilter(){
      if((!Array.isArray(this.abonnements) && this.toShow=='abonnements') || (!Array.isArray(this.factures) && this.toShow=='factures')){
        this.$store.state.cliquable=false
      }
      else{
        //Si on est sur la page accueil seulement
        if(this.page=="accueil"){
          this.$store.state.cliquable=true
        }
      }
    }
  },
  watch:{
    page(){
      setTimeout(()=>{
        fadeIn.addFadeInFalse()
      }, 100);

      if(this.page!="documents" && this.page!="registre"){
        document.title = this.page_global().meta_google.compte.title
      }
    },
    afficherCookies(){
      setTimeout(()=>{
        fadeIn.addFadeInFalse()
      }, 100);
    },
    toShow(){
      this.loadFilter()
    },
    abonnements(){
      this.loadFilter()
      setTimeout(()=>{
        fadeIn.addFadeInFalse()
      }, 100);
    },
    factures(){
      this.loadFilter()
    },
    maxFactures(){
      setTimeout(()=>{
        fadeIn.addFadeInFalse()
      }, 100);
    }
  },
  beforeCreate(){
      var mesDonnees = new FormData();
      mesDonnees.set("mail", this.$store.state.currentUser.mail);
      axios({
        method: 'post',
        url: json_config.phpFiles_url + '/getSites.php',
        data: mesDonnees
      })
      .then((result) => result.data)
      .then((result) => {
          this.abonnements = result;
          console.log(result)
          console.log(this.abonnements==[])
          console.log(this.abonnements=="[]")
          console.log(this.abonnements=='')
          console.log(this.howManyAbo('active')+this.howManyAbo('pending-cancel'))
      })  

      mesDonnees.set("type", "get");
      axios({
        method: 'post',
        url: json_config.phpFiles_url + '/infosCustomer.php',
        data: mesDonnees
      })
      .then((result) => result.data)
      .then((result) => {
          this.infos = result;
          this.oldInfos = result
          for (let i=0; i<result.meta_data.length; i++){
            if(result.meta_data[i].key=="tva"){
              this.index_tva=i
              i=result.meta_data.length
            }
          }
      })

      var mesDonnees = new FormData();
      mesDonnees.set("mail", this.$store.state.currentUser.mail);
      axios({
        method: 'post',
        url: json_config.phpFiles_url + '/factures.php',
        data: mesDonnees
      })
      .then((result) => result.data)
      .then((result) => {
          console.log(result)
          this.factures = result;
          var pdf = []
          result.forEach(element => {
            //Si il y a bien un élément "pdf" dans les meta_data
            if(element.meta_data.filter(res => res.key=="pdf").length>0){
             pdf.push(element.meta_data.filter(res => res.key=="pdf")[0].value) 
            }
            else{
              pdf.push('')
            }
          })
          this.facturesPdf = pdf
      }) 

      //On get les produits
      axios.get(json_config.phpFiles_url + "/produits.php")
      .then((result) => result.data)
      .then((result) => {
          this.produits = result;
          console.log(result)
      })  
  },
  mounted(){
    if(!this.$store.state.currentUser.mail){
        this.$router.push('./connexion')
    }
    this.$store.state.cliquable=true
  }
}
</script>

<style scoped>
.background_cookies_help{
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: grey;
  opacity: 0.9;
  z-index: 1;
}

.container_global_margin{
  margin-left: 20px;
  margin-right: 20px;
}

.partie_factures{
  margin-bottom: 80px;
}

.sub_header{
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #CCCBCB;
}

.sub_header_left{
  justify-content: left;
  margin-top: 20px;
}

.sub_header > div{
  margin-right:20px;
  margin-bottom: 5px;
  cursor: pointer;
  border-bottom: 1px solid;
}
.sub_header > div:hover{
  border-bottom: 1px solid #04D98B;
}

.sub_header > div > a{
  color: #CCCBCB;
  text-decoration: none
}

.sub_header > div > a:hover{
  text-decoration:none;
}

.underline{
  border-bottom: 1px solid #04D98B!important;
}

.documents_docassemble_right{
  width:-webkit-fill-available;
  margin: right 20px;
  margin-left: 20px;
}

.siteConcerneCookies{
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #022873;
}

.partie_compte_flex{
  display: flex;
  justify-content: space-evenly;
}

.arrow_left{
  margin-left: 20px;
  font-size: 34px;
  transition: all .5s
}

/*.arrow_left:hover{
  transform: rotate(360deg);
}*/

.paiement_stripe{
  width:300px;
  padding: 16px;
  background: #F2F3FB;
  border-radius: 10px;
}

.paiement_precision{
  background: #F2F3FB;
  border-radius: 10px;
  width: 570px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;

  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #022873;
}

.partie_compte_paiement{
  text-align: left;
  padding-bottom: 100px;
}

.paiement_sous_titre{
  margin-top: 56px;
  margin-bottom:16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.annulerModifs{
  margin-top:19px;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;

  color: #AFAFAF;
  cursor: pointer;
}

input, select{
  margin-top: 12px;
  padding-top: 21px;
  padding-left: 8px;
  padding-bottom: 6px;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 100%;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  box-sizing: border-box;

  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

select{
  cursor: pointer;
}

input:focus-visible, select:focus-visible{
    outline: #42b983 auto 1px;
}

.spacebetween{
  width: 16px;
}

.flex{
  display: flex;
}

input::placeholder{
  color: #AFAFAF;
}

.separation{
  height: 1px;
  width: -webkit-fill-available;
  background-color: #CCCBCB;
  margin-top: 80px;
  margin-bottom: 48px;
}

.red{
  color: red;
}

.green{
  color: #04D98B;
}

.left{
  text-align: left;
}

.main{
  width: -webkit-fill-available;
  min-height: 100%;
}

.compte_header{
  padding-top: 178px;
}

.partie_compte{
  width: 1074px;
  margin-left:auto;
  margin-right: auto;
}

.partie_abonnements_content{
  width: 884px;
  margin-left:auto;
  margin-right: auto;
}

.main_title{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
}

.main_title_underline{
  height:1px;
  background-color: #04D98B;
  width: 174px;
  margin-bottom: 64px;
}

.carteAbonnement {
  width: -webkit-fill-available;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 2px 12px #F4F3F3;
  border-left: 10px solid #022873;
  margin-bottom: 42px;
  padding-bottom: 16px;
  padding-top: 8px;
  padding-left: 11px;
  padding-right: 16px;
}

.headerAbonnement {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #AFAFAF;
}

.carte_nom_site{
  margin-top: 24px;
  margin-bottom: 8px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #04D98B;
  word-wrap: break-word;
}

.carte_renouvellement{
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #AFAFAF;
}

.footerAbonnement {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  line-height: 30px;
  border-radius: 8px;
  /*border-top: 1px solid black;*/
}

.carteInfo {
  padding: 10px;
}

.footerInfo {
  background: #022873;
  border: 1px solid #022873;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 6px;
  margin-left: 6px;
  display: flex;
  align-items: center;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 28px;
  padding-right: 28px;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #FFFFFF;
  transition:all .3s
}

.footerInfo:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.carte_bottom_right{
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: 8px;
  
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #B4B4B4;
}

.carte_bottom_right_general{
  width:fit-content;
}

.carte_bottom_right_general:hover{
  cursor: pointer;
}

.partie_infos_content{
  width: 843px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 2px 12px #F4F3F3;
  border-radius: 10px;
  padding-top: 32px;
  padding-bottom: 30px;
}

.partie_infos_content_general{
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.partie_infos_content_between{
  width: 1px;
  background-color: #CCCBD6;
}

.partie_infos_content_title{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.partie_infos_content_content{
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.partie_infos_content_content > div{
  /*margin-top: 12px;*/
}

.partie_infos_content_content > div:nth-of-type(1){
  margin-top: 24px;
}

.partie_infos_content_border{
  width: 50%;
  padding-left: 64px;
  padding-right: 20px;
}

.partie_infos_button{
  margin-top: 75px;
  /*padding-bottom: 30px;*/
  display: flex;
  justify-content: space-around;
}

.partie_factures_button{
  margin-top: 85px;
  display: flex;
  justify-content: space-around;
}

.boutton_offre{
  position: inherit;
}

.boutton_offre_popup{
  transform: none;
  transition: transform 0s;
}

.partie_factures_content{
  width:843px;
  margin-left: auto;
  margin-right: auto;
}

.partie_factures_facture{
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  border-bottom: 1px solid #CCCBCB;
  align-items: center;

  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

a.facture{
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #F2505D;
  text-decoration:none;
  color: #F2505D;
  box-sizing: border-box;
  border-radius: 10px;
  transition: all .3s
}

a.facture:hover{
  transform: scale(1.05);
}

.sous_titre_left{
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  min-width: fit-content;
}

.sous_titre{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sous_titre_right{
  height: 1px;
  background-color: #CCCBCB;
  width: -webkit-fill-available;
  margin-left: 10px;
}

@media screen and (max-width: 1100px) {
  .partie_abonnements_content, .partie_infos_content, .partie_factures_content{
    width: 560px;
  }

  .partie_compte{
    width: 700px;
  }
}

@media screen and (max-width: 750px) {
  .partie_abonnements_content, .partie_infos_content, .partie_factures_content{
    width: 85%;
  }

  .partie_compte{
    width: 95%;
  }

  .partie_infos_content_general{
    flex-direction: column;
    align-items: center;
  }

  .partie_infos_content_border{
    margin-bottom: 40px;
    width: auto;
    padding: 0px;
    text-align: center;
  }

  .partie_infos_button{
    margin-top: 0px;
  }
}

@media screen and (max-width: 650px) {
  .paiement_precision{
    width: 400px;
  }
}
@media screen and (max-width: 550px) {
  .footerAbonnement{
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }

  .footerInfo{
    margin: 5px;
  }

  .partie_compte_flex{
    flex-direction: column;
  }

  .container_global_margin{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .paiement_precision{
    width: 250px;
  }

  .paiement_stripe{
    width:250px;
  }
}

@media screen and (max-width: 400px) {
  .partie_infos_button{
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>