<template>
    <select v-model="pays" @change="sendValue">
        <option value="" selected hidden>{{ page_global().listOfCountries_placeholder }}</option>
        <option v-for="(country, indexCountry) in page_global().listOfCountries" :key="indexCountry" :value="country[0]">{{ country[1] }}</option>
    </select>
</template>

<script>
import page_glb from "@/json/page_global.json"
export default {
  name: 'Pays',
  props:["model"],
  emits:["recupPays"],
  data(){
      return{
          page_glb: page_glb,
          pays:this.model
      }
  },
  methods:{
      page_global(){
          return page_glb[this.$store.state.codeLangue]
      },
      sendValue(){
          this.$emit("recupPays", this.pays)
      }
  }
    
}
</script>